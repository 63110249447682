@import "https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i";
@import "https://fonts.googleapis.com/css?family=Heebo:700,700i";
.txt-huge {
  font-size: 2rem;
}

.txt-large {
  font-size: 1.5rem;
}

.txt-small {
  font-size: 0.85rem;
}

html {
  font-size: large;
  overflow: hidden;
  height: 100%;
}
body {
  font-family: 'Open Sans', sans-serif;
  color: #37434A;
  font-size: inherit;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  min-height: -webkit-fill-available;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1.05rem;
  line-height: 1.5rem;
  margin: 0;
}

a {
  color: #37434A;
}

a .txt-muted {
  color: #9ecbf1;
}

.bold {
  font-weight: bold;
}

.pageHeader,
.pageFooter,
#match-view-confirm {
  background-color: #337ab7;
  color: white;
  text-align: center;
  padding: 0.75rem 1.75rem;
  width: 100%;
  position: relative;
  flex: none;
  min-height: 55px;
  max-height: 55px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageFooter {
  position: fixed;
  bottom: 0;
  left: auto;
  right: auto;
  cursor: pointer;
  max-width: 800px;
  line-height: 150%;
}
.pageHeader-back {
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  left: .5rem;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}
.pageHeader.smaller h1 {
  font-size: .9rem;
  line-height: 1.2rem;

  span {
    display: block;
  }
}
.pageFooter img,
#match-view-confirm img {
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  right: .5rem;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  transition: right 300ms ease-out;
  opacity: 1;
  -webkit-animation: blink 1.5s ease infinite 1.5s;
  animation: blink 1.5s ease infinite 1.5s;
}
.pageFooter img.offscreen,
#match-view-confirm img.offscreen {
  right: 150%;
  transition: right 0ms ease-out;
  animation:none;
}
@keyframes blink {
    0% {opacity:1;}
    50% {opacity:0;}
    100% {opacity:1;}
}
@-webkit-keyframes blink {
    0% {opacity:1;}
    50% {opacity:0;}
    100% {opacity:1;}
}

@keyframes colorSwitch {
    0% {background:#337ab7;}
    50% {background:#539ad7;}
    100% {background:#337ab7;}
}
@-webkit-keyframes colorSwitch {
    0% {background:#337ab7;}
    50% {background:#539ad7;}
    100% {background:#337ab7;}
}
.pageFooter.animate,
#match-view-confirm.animate {
  -webkit-animation: colorSwitch 3s ease infinite;
  animation: colorSwitch 3s ease infinite;
}
.pageFooterSpacer {
  width: 100%;
  height: 55px;
  margin: 0;
  border: 0;
  opacity: 0;
  flex: none;
  display: block;
  position: static;
  z-index: -10;
}
#match-view-confirm {
  background-color: unset;
  color: unset;
  border: 2px #337ab7 solid;
  border-radius: 5px;
  margin: 0 5px 5px 5px;
  width: ~"calc(100% - 10px)";
  font-size: .9rem;
  text-align: left;
  padding-left: 1em;
  display: block;
  cursor: pointer;
  user-select: none;
}
#match-view-confirm.gray {
  color: #aaa;
  border-color: #aaa;
  cursor: unset !important;
}
.match-list-scroll {
  border-top: 3px #ddd solid;
  overflow: auto;
  flex: 1;
}

.linkRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  min-height: 55px;
  line-height: 1.33rem;
  text-decoration: none;
  color: #222;
  border-bottom: 1px solid #ccc;
  position: relative;
  text-align: center;
}
.linkRow-left {
  margin: auto;
}
.linkRow-right {
  text-align: right;
  white-space: nowrap;
}
.device-list .linkRow {
  line-height: unset;
}
.follow-list .linkRow-right {
  white-space: normal;
}
.linkRow:hover, .linkRow:active, .linkRow:focus {
  text-decoration: none;
}
.right-blue-arrow {
  position: absolute;
  width: 20px;
  height: 100%;
  right: 1rem;
  margin: 0;
  transform-origin: 50%;
  transition: transform 200ms ease, right 300ms ease;
  transform: rotate(-90deg);
}
.linkRow.selected {
  background: #def;
}


.field {
  margin: 1rem 0;
}
.field-label {
  color: #37434A;
  text-transform: uppercase;
  font-size: 0.85rem;
  font-weight: 700;
}
.field-value {
  font-weight: 600;
  font-size: 1rem;
}

.loginForm-email {
  margin: 0;
}
.loginForm-email input {
  border-bottom: 0;
}
.loginForm input {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
}
.loginForm .form-control {
  height: 3.5rem;
  padding: 18px 0 6px 0;
  font-size: 1rem;
  outline: none !important;
  border-top: 0;
  border-bottom: 1px solid #ccc;
}
.loginForm .form-control:focus {
  outline: none;
  box-shadow: none;
}

.divider {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #DDD;
}
.divider-text {
  padding-right: 1rem;
  padding-left: 1rem;
  color: gray;
}
.divider::before, .divider::after {
  flex: auto;
  content: "";
  position: relative;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

#coach_app {
  height: 100%;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  min-height: -webkit-fill-available;
}

@media all and (min-width: 801px) {
  #coach_app {
    border-left: 1px #ddd solid;
    border-right: 1px #ddd solid;
  }
}

.device-list,
.team-list,
.match-list-wrap,
#match-view-wrap {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  flex: 1;
}
.device-list > div,
.team-list > div,
.match-list-wrap > div {
  height: 100%;
  overflow: auto;
}
.match-list-wrap > div {
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
}
.match-list-wrap > div.initializing {
  display: unset;
}

#match-view-inner-wrap {
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  height: 100%;
  flex: 1;
}

#match-data-wrap {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
}


.navbar {
  border-bottom: 1px solid #e7e7e7;
}

#coach_app::before {
  content: '';
  position: static;
  width: 100%;
  height: 50px;
  flex: none;
}
#coach_app.hide-navbar::before {
  display: none;
}

.navbar-brand {
  font-family: "Heebo";
  font-weight: 700;
  font-size: 1.25rem;
  color: #37434A !important;
}
.navbar-toggle {
  margin-top: 9px;
}
.navbar-brand span {
  color: #337ab7;
  font-style: italic;
}
@media all and (max-width: 270px) {
  .navbar-brand {
    display: none;
  }
}


.pageNav {
  display: flex;
  justify-content: center;
  font-size: .75rem;
  margin: 3px 2.5px;
  background: #eee;
  border-radius: 6px;
  height: 30px;
}
.pageNav > * {
  margin: 0 0.2em;
}
.pageNav-item {
  position: relative;
  color: #37434A;
  font-weight: bold;
  text-align: center;
  display: block;
  height: 30px;
  overflow: hidden;
  border-radius: 5px;
}
.pageNav-item.is-selected {
  color: #337ab7;
  font-weight: bold;
  background: #ddd
}
.pageNav-item[disabled] {
  color: #bbb;
}
.pageNav-item .long-label {
  display: inline;
}
.pageNav-item .short-label {
  display: none;
}
.pageNav .owl-nav button {
  background: rgba(238,238,238,0.5) !important;

  span {

  }
}
@media all and (max-width: 24rem) {
  .pageNav-item .long-label {
    display: none;
  }
  .pageNav-item .short-label {
    display: inline;
  }
}

#match-filter-select {
  height: 50px;
  border: none;
  background: #eee;
  margin: 5px auto;
  width: ~"calc(100% - 10px)";

  .pageNav-item {
    height: 50px;
    border-radius: 5px;
  }
  .pageNav-item.is-selected {
    margin-top: 3px;
    height: 44px !important;

    button {
      background: #ddd !important;
      border-radius: 5px;
      height: 100%;
    }
  }
  .btn {
    height: 50px;
    font-size: 13px;
  }
  .owl-nav {
    button {
      height: 50px;
      background: rgba(238,238,238,0.5) !important;

      span {
        line-height: 29px;
      }
    }
  }
  .owl-nav.disabled {
    display: none !important;
  }
}

.match-view-game-count {
  line-height: 1.5rem;
}

.split {
  display: flex;
  justify-content: space-between;
  border: solid #ddd 2px;
  margin: 3px;
  border-radius: 5px;
  min-height: 90px;
}
.split > * {
  flex: 0 0 40%;
  text-align: center;
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.split-label {
  font-weight: bold;
}
.split-value {
  font-size: 2rem;
  line-height: 2.3rem;
  font-weight: bold;
}
.is-sticky .split.sticky {
  z-index: 100 !important;
}
.is-sticky .split.sticky .split-label {
  display: inline-block;
  padding-right: 0.2rem;
}
.is-sticky .split.sticky .split-value {
  display: inline-block;
}
.split-center {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: .75em;
  font-weight: bold;
  display: flex;
  flex-flow: column;
  justify-content: center;
  user-select: none;

  div {
    flex: none;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-flow: column;

    div {
      border-radius: 5px;
      padding: 0 5px
    }
  }
}
.split-center .timeout-display {
  font-size: 0.9em;
  position: fixed;
  bottom: 0;
  width: 100%;
  line-height: 1em;
}
.split-center .highlight {
  background: #ddd;
}

.winLossBadge {
  color: #CCC;
  font-size: 1.5rem;
  display: inline-block;
  width: 1em;
  text-align: center;
  margin-left: .5rem;
}

.actionBlock,
.playerBlock {
  background-color: #eee;
  padding: 2rem;
  text-align: center;
  border-bottom: 2px solid #37434A;
}

.divider {
  margin: 2rem 0 0.5rem;
}

.divider-text {
  font-size: 0.85rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #EB813D;
}

.loginCtrls {
  display: flex;
  align-items: center;
}
.loginCtrls > * {
  flex: 1;
}
.loginCtrls > *:nth-child(2) {
  text-align: right;
}

.btn {
  font-size: 1rem;
  font-weight: 700;
}

.tabNav {
  background-color: #337ab7;
  display: flex;
  list-style-type: none;
  padding: 0;
  margin-bottom: 2rem;
}
.tabNav li {
  flex: 1;
  text-align: center;
}
.tabNav li a.active {
  color: white;
}
.tabNav a.active:after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 20px;
  height: 20px;
  background-color: white;
}
.tabNav a {
  color: rgba(255, 255, 255, 0.6);
  display: block;
  padding: 1rem;
  font-weight: bold;
  text-decoration: none;
  position: relative;
}
.tabNav a.is-active, .tabNav a:hover {
  color: white;
}
.tabNav a.is-active:after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 20px;
  height: 20px;
  background-color: white;
}

.alert {
  margin-bottom: 0;
  padding: 1.5rem 1rem;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
  display: block;
  border: 0;
  outline: 0;
  box-shadow: 0;
  padding: .5rem 0 0.5rem;
  margin: 1rem 0;
  border-bottom: 1px solid #ccc;
}

.navbar-default .navbar-toggle {
  border: 0;
}

button[type='submit'] {
  background-color: #0070e2;
  border: 0;
  width: 100%;
  color: white;
  font-weight: bold;
  padding: 0.75rem;
  margin: 1rem 0;
}

.checkbox-inline label {
  font-weight: 400 !important;
}

.block {
  margin: 1rem;
}

.gameLog {
  text-align: center;
}

.playerLog {
  font-size: 0.8rem;
  width: 100%;
  table-layout: fixed;
}
.playerLog th, .playerLog td {
  padding: .25rem .5rem;
}
.playerLog tr {
  border-bottom: 1px solid #d8dee1;
}

#playerStats,
#rotationStats {
  width: 100%;
  height: 100%;
  text-align: right;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  flex: 1 1 auto;
}
.playerStats-header,
.rotationStats-header {
  font-weight: bold;
  width: 100%;
  flex-flow: row nowrap;
  flex: none;
  display: flex;
  border-bottom: 1px #ddd solid;
}
.playerStats-scroll,
.rotationStats-scroll {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 100%;
  overflow: auto;
}
.playerStats-header,
.playerStats-scroll > div, 
.rotationStats-header,
.rotationStats-scroll > div {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 1px #ddd solid;
  flex: 1 0 auto;
}
.playerStats-scroll > div:last-child,
.rotationStats-scroll > div:last-child {
  position: fixed;
  bottom: 0;
  background: #fff;
  border-top: 1px #ddd solid;
  height: 30px;
  max-width: 798px;
}
.playerStats-scroll > div:nth-last-child(2),
.rotationStats-scroll > div:nth-last-child(2) {
  margin-bottom: 29px;
}
.playerStats-scroll > div:nth-last-child(2),
.rotationStats-scroll > div:nth-last-child(2) {
  margin-bottom: 29px;
}
.playerStats-header > div,
.playerStats-scroll > div > div,
.rotationStats-header > div,
.rotationStats-scroll > div > div {
  font-size: 0.75rem;
  padding: 0.25rem;
  flex: 1;
  align-self: center;
  overflow: hidden;
}
.playerStats-header > div:first-child,
.playerStats-scroll > div > div:first-child,
.rotationStats-header > div:first-child,
.rotationStats-scroll > div > div:first-child {
  text-align: left;
  flex: none;
  width: 20%;
  overflow: visible;
}

.gameLog {
  font-size: 0.75rem;
  width: 100%;
  height: 100%;
  table-layout: fixed;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
}
.gameLog tbody {
  width: 100%;
  display: block;
  height: 100%;
  overflow: auto;
}
.gameLog thead {
  background-color: #acb9c1;
  color: white;
  display: block;
  width: 100%;
}
.gameLog th,
.gameLog td {
  text-align: center;
  padding: 0.25rem;
  display: inline-block;
}
.gameLog tr {
  border-bottom: 1px solid #d8dee1;
  display: block;
  width: 100%;
}
.gameLog-action {
  width: 34%;
}
.gameLog-player, .gameLog-jersey, .gameLog-score {
  width: 22%;
}
.gameLog-score {
  font-weight: bold;
}
.gameLog-timeout {
  background-color: #d8dee1;
  font-weight: bold;
  padding: 0.5rem !important;
  width: 100%;
}
.gameLog .badge {
  font-size: .75rem;
}
.gameLog tr.highlight {
  background-color: rgba(255, 255, 0, 0.2);
}

.subPageHeader {
  display: block;
  background-color: #505e66;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  padding: 0.5rem;
  border-bottom: 1px solid #8fa0ab;
  position: relative;
}
.subPageHeader-toggle {
  position: absolute;
  width: 1rem;
  height: 1rem;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.35s;
}
.subPageHeader.is-closed .subPageHeader-toggle {
  transform: translateY(-50%) rotate(180deg);
}
.subPageHeader:hover, .subPageHeader:active, .subPageHeader:focus {
  color: white;
  text-decoration: none;
}

.actionTable {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  flex: 1 1 auto;
}
.actionTable-header {
  font-weight: bold;
  width: 100%;
  flex-flow: row nowrap;
  flex: none;
  display: flex;
  border-bottom: 1px #ddd solid;
}
.actionTable-header > div {
  flex: 1 0 auto;
  padding: 0.25rem;
  font-size: 0.85rem;
  width: 30%;
}
.actionTable-scroll {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 100%;
  overflow: auto;
}
.actionTable-scroll > div {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 1px #ddd solid;
  flex: 1 0 auto;
}
.actionTable-scroll > div > div {
  font-size: 0.85rem;
  padding: 0.25rem;
  flex: 1;
  align-self: center;
}
.actionTable-count {
  width: 30%;
}
.actionTable-action {
  color: #505e66;
}
.actionTable-error-action {
  color: #ee1111;
}
.actionTable-error-action-count {
  color: #ee1111;
}
.actionTableExtra {
  background-color: #cce5fb;
}
.errtree-header {
  font-weight: bold;
}
.actionTable-count.errtree-header {
  font-size: 1rem !important;
}


.timeout-display {
  height: 20px;
  flex: 0 0 auto !important;
  font-weight: normal;
  opacity: 100;
  transition: height 150ms linear, opacity 150ms linear 180ms;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.timeout-display.inactive {
  height: 0;
  opacity: 0;
  transition: height 150ms linear 150ms, opacity 150ms linear;
}

.timeoutCircle {
  display: inline-block;
  width: .5rem;
  height: .5rem;
  border-radius: .5rem;
  border: 1px solid #37434A;
}
.timeoutCircle.is-used {
  background-color: #37434A;
}


.txt-green {
  color: #32b04f;
}

.txt-red {
  color: #e33360;
}

.is-us {
  background-color: #4acb67;
  color: white;
}

.is-them {
  background-color: #e33360;
  color: white;
}

.no-visible-following {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 50px;
}

.spinner {
  width: 30px;
  height: 30px;
}

.initializing {
  margin: 15px;
  font-size: 16pt;
}

.stat-btn {
  font-size: small;
  margin: 1px !important;
}

.game-btn {
  font-size: 12px;
  padding: 2px 4px;
  color: inherit !important;
  width: 100%;
  background-color: transparent !important;
  height: 30px;
  overflow: hidden;
}
.game-btn:hover {
  cursor: move;
  cursor: grab;
}
.game-btn:active {
  cursor: move;
  cursor: grabbing;
}
.btn, .btn:hover, .btn:visited, .btn:focus { 
  color: black; 
  background-color: transparent;
}
.btn:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.modal-dialog-outer {
  z-index: 2000;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-dialog-inner {
  z-index: 2001;
  width: 90%;
  max-width: 500px;
  min-height: 100px;
  max-height: 50%;
  border: 2px #337ab7 solid;
  border-radius: 5px;
  display: flex;
  flex-flow: column nowrap;
  background: #fff;
  flex: none;
  text-align: center;
  align-items: center;
}
.modal-dialog-header {
  width: 90%;
  border-bottom: 1px #ddd solid;
  padding: 10px;
  font-weight: bold;
  font-size: 1.2rem;
}
.modal-dialog-body {
  margin: 20px 10px;
  width: 90%;
  font-size: .8rem;
}
.modal-dialog-buttons {
  margin: 0 10px 10px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-dialog-button {
  border: 1px #337ab7 solid;
  border-radius: 7px;
  flex: 0 1 40%;
  padding: 5px;
  font-size: .8rem;
  font-weight: bold;
  margin: 5px;
  user-select: none;
}
.modal-dialog-button:active {
  background-color: #def;
}
.close-button {
  border-color: #bbb;
}

#carousel-selector-outer-wrap {
  border: 2px #337ab7 solid;
  border-radius: 5px;
  margin: 0 3px 3px;
}
#carousel-selector-wrap {
  margin: 0 3px 3px;
  max-height: 150px;
  transition: max-height 200ms ease, margin 200ms linear;
  overflow: hidden;
  flex: 0 0 auto;
}
#carousel-selector-wrap.closed {
  max-height: 0px;
  margin: 0 3px;
}

#carousel-selector-toggle {
  margin: 3px;
  border-color: #337ab7;
  font-weight: bold;
  position: relative;
  flex: none;
  background-color: unset;
  display: flex;
  align-items: center;
  height: 25px;
}
.carousel-selector-toggle-arrow {
  position: absolute;
  width: 20px;
  right: 1rem;
  margin: 0;
  transform-origin: 50%;
  transition: transform 200ms ease;
}
.arrow-up {
  transform: rotate(-180deg);
}
.arrow-down {
  transform: rotate(0deg);
}
#carousel-selector-summary {
  height: 0;
  margin: 0;
  overflow: hidden;
  border: 0;
  flex: none;
  transition: height 0ms;
  font-weight: bold;
  background: #337ab7;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  border-radius: 0;
}
#carousel-selector-summary.show-summary {
  height: 30px;
  transition: height 200ms linear;
}
@media all and (max-width: 360px) {
  #carousel-selector-summary {
    font-size: 4vw;
  }
}



.owl-carousel {
  position: relative;
  margin-left: 0;
  margin-right: 0;
}
.owl-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  font-size: 25px;
  height: 0;
}
.owl-nav.disabled {
  display: block !important;
}
.owl-nav button {
  height: 30px;
  width: 27px;
  background: rgba(230, 230, 230, 0.5) !important;
  border-radius: 5px;
  overflow: hidden;
}
.owl-nav button span {
  line-height: 25px;
  vertical-align: text-top;
}
.owl-next {
  float:right;
}
